import { CrudModel } from './_crud';

export class ProjectModel extends CrudModel {
	constructor() {
		super('admin/project');
	}

  async createStorageBucket(id: string, options: { subdomain?: string; domain?: string } = {}) {
    return this.post(id, 'createStorageBucket', options);
  }

  async createBackendService(id: string, options: { prodDomain?: string; prodSubdomain?: string; devDomain?: string; devSubDomain?: string; databaseName?: string; databaseUser?: string; databasePassword?: string; } = {}) {
    return this.post(id, 'createBackendService', options);
  }

  async createFrontendService(id: string, options: { prodDomain?: string; prodSubdomain?: string; devDomain?: string; devSubDomain?: string; } = {}) {
    return this.post(id, 'createFrontendService', options);
  }

  async createAdminService(id: string, options: { prodDomain?: string; prodSubdomain?: string; devDomain?: string; devSubDomain?: string; } = {}) {
    return this.post(id, 'createAdminService', options);
  }
}

export default new ProjectModel();
