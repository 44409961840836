




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "../layouts/components/EntityCrud.vue";
import projectModel from "../models/project.model";

@Component({
  components: { EntityCrud },
})
export default class Projects extends Vue {
  model: any = projectModel;

  title = "Projetos";
  tableColumns = ["name", "slug", "atlassianKey", "createdDate"];

  formColumns = ["name", "slug", "atlassianKey", "apiDevUrl", "frontendDevUrl", "adminDevUrl", "apiProdUrl", "frontendProdUrl", "adminProdUrl", "serviceAccountProd", "bucketProd"];
  defaultEntity = {};
}
